import React, { useState, useRef, useEffect } from 'react';

function CustomInput({ name, label, unit, range, default: defaultValue, onChange }) {
  const [error, setError] = useState(false);
  const ref = useRef();
  const [, setInputValue] = useState(defaultValue);

  const handleInput = (event) => {
    event.preventDefault();
    const input = event.target.value;
    if (isNaN(input)) {
      setError("Choose a valid number");
    } else if (parseFloat(input) >= range.lower && parseFloat(input) <= range.upper) {
      setError(false);
      setInputValue(input);
      if (onChange) {
        console.log("handleInput onChange called for name:", name, "with value:", input);
        onChange(event.target.value, name);
      }
    } else {
      setError(`Choose a number in the range of ${range.lower} to ${range.upper}`);
    }
  };

  
    useEffect(() => {
      
    })

  /*useEffect(() => {
    console.log("CustomInput useEffect triggered for name:", name);
    const input = parseFloat(defaultValue);
    if (isNaN(input)) {
      setError("Choose a valid number");
    } else if (input >= range.lower && input <= range.upper) {
      setError(false);
      setInputValue(input);
      if (onChange && inputValue !== input) {
        //console.log("CustomInput onChange called for name:", name, "with value:", input);
        onChange(input, name);
      }
    } else {
      setError(`Choose a number in the range of ${range.lower} to ${range.upper}`);
    }
  }, [defaultValue, range.lower, range.upper, onChange, name, inputValue]); */

  return (
    <div key={name} className="flex flex-wrap mb-3">
      <div className="w-full">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          {label}
        </label>
        <div className="relative text-right">
          <span className="absolute inset-y-0 right-2 pl-3 flex items-center pointer-events-none">
            {unit}
          </span>
          <input
            name={name}
            ref={ref}
            className={
              error
                ? "appearance-none block w-full bg-white text-gray-700 rounded-lg border-red-500 focus:border-red-500 border-2 py-2 px-2 mb-0 leading-tight focus:outline-none"
                : "appearance-none block w-full bg-white text-gray-700 rounded-lg py-2 px-2 mb-3 border-2 leading-tight focus:outline-none focus:border-[#005B7F]"
            }
            type="text"
            defaultValue={defaultValue}
            onChange={handleInput}
          />
        </div>
        <p
          className={
            error
              ? "text-red-500 text-xs italic"
              : "hidden text-red-500 text-xs italic"
          }
        >
          {error}
        </p>
      </div>
    </div>
  );
}

export default CustomInput;