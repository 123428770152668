import React from "react";
import { simuParameters } from "../App";
import { useRecoilState } from "recoil";

function SimuDropDown(props) {
  const [simuParams, setsimuParams] = useRecoilState(simuParameters);

  const changeSimuParam = async (param) => {
    props.setcurrentSelect(param);
    setsimuParams({
      ...simuParams,
      sweepParams: {
        ...simuParams.sweepParams,
        [props.id]: {
          name: param.shortName,
          lower: param.range.lower,
          upper: param.range.upper,
        },
      },
    });
    props.setopen(!props.open);
  };

  return (
    <div className="relative">
      <div className="absolute bg-white w-full rounded-lg z-10">
        <ul className="bg-white border-2 rounded-lg">
          {props.notUsedParams.map((param, i) => {
            return (
              <li key={i}>
                <button
                  className="w-full text-left hover:bg-[#005B7F] hover:text-white py-2 px-2 rounded-lg"
                  onClick={() => changeSimuParam(param)}
                >
                  {param.fullName}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default SimuDropDown;
