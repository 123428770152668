import { useState } from "react";
import axios from "axios";
import Login from "./login";
import { useNavigate } from "react-router-dom";
import { FOOTER_TEXT, VERSION_NUM } from "./svgs";
import { baseUrl} from "../App";

function Signup() {
    const initialValues = {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [signupSuccess, setSignupSuccess] = useState(false); // State for signup success
    const [signupError, setSignupError] = useState(""); // State for signup error
    const navigate = useNavigate();

    const [showlogin, setShowlogin] = useState(false);
    const handleloginClick = () => {
        setShowlogin(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleloginSuccess = () => {
        setShowlogin(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if(Object.keys(errors).length === 0) {
            const url = baseUrl +"/post_signup/";
            const formData = {
                username: formValues.username,
                email: formValues.email,
                password: formValues.password
            };

            axios.post(url, formData)
                .then(() => {
                    setSignupSuccess(true); // Set signup success to true on successful signup
                    setTimeout(() => {
                        setSignupSuccess(false);
                        navigate("/");
                    }, 5000); // Hide the success message after 5 seconds
                })
                .catch((error) => {
                    if (error.response && error.response.status === 409) {
                        setSignupError("Username or email already exists.");
                    } else {
                        setSignupError("Signup failed. Please try again.");
                    }
                });
        } else {
            setSignupError("All fields are required.");
        }
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.username) {
            errors.username = "Username is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 4) {
            errors.password = "Password must be more than 4 characters";
        } else if (values.password.length > 18) {
            errors.password = "Password cannot exceed more than 18 characters";
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Those passwords didn’t match. Try again.";
        }
        return errors;
    };

    const TopBar = () => {
        return (
            <div className="bg-[#179C7D] p-4 text-center fixed top-0 left-0 right-0 z-10">
                <img src="/14_FhG_white.png" alt="Logo" className="mx-auto w-56 h-auto" />
            </div>
        );
    };

    return (
        <>
            {showlogin ? (
                <Login onLoginSuccess={handleloginSuccess} />
            ) : (
                <>
                    <TopBar />
                    <div className="flex justify-center items-center h-screen bg-gray-200">
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-2xl font-bold mb-8 text-gray-800">Sign Up</h2>
                            {signupSuccess && (
                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-sm flex items-center" role="alert">
                                    <strong className="font-bold mr-1">Success: </strong>
                                    <span className="block sm:inline">Signup successful!</span>
                                    <span className="ml-auto" onClick={() => setSignupSuccess(false)}>
                                        <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.707z"/></svg>
                                    </span>
                                </div>
                            )}
                            {signupError && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-sm flex items-center" role="alert">
                                    <strong className="font-bold mr-1">Error: </strong>
                                    <span className="block sm:inline">{signupError}</span>
                                    <span className="ml-auto" onClick={() => setSignupError("")}>
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.707z"/></svg>
                                    </span>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                                    <input type="text" id="username" name="username" value={formValues.username} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <p className="text-red-500 text-xs italic">{formErrors.username}</p>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                    <input type="email" id="email" name="email" value={formValues.email} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <p className="text-red-500 text-xs italic">{formErrors.email}</p>
                                <div className="mb-4">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                    <input type="password" id="password" name="password" value={formValues.password} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <p className="text-red-500 text-xs italic">{formErrors.password}</p>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                                    <input type="password" id="confirmPassword" name="confirmPassword" value={formValues.confirmPassword} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <p className="text-red-500 text-xs italic">{formErrors.confirmPassword}</p>
                                <button type="submit" className="w-full bg-[#179C7D] hover:bg-[#148C70] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Sign Up</button>
                                <label className="block text-sm font-medium text-gray-700 mt-4 text-center">Already have an account?</label>
                                <button type="button" onClick={handleloginClick} className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded mt-4 focus:outline-none focus:shadow-outline">Login</button>
                            </form>
                            <div className="fixed bottom-0 right-2 text-black-700 text-sm">
                            {FOOTER_TEXT}
                            </div>
                            <div className="fixed bottom-0 left-2 text-black-700 text-sm">
                            {VERSION_NUM}
                            </div>
                        </div> 
                    </div>
                </>
            )}
        </>
    );
}

export default Signup;