import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl} from "../App";

export default function Header(props) {
  const [counter, setCounter] = useState(900); // 15 Minuten in Sekunden

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const response = await axios.post(baseUrl +"/logout/", {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            // Nachricht aus dem Backend in der Konsole anzeigen
            console.log(response.data.message);
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    }
    localStorage.removeItem('token'); // Token löschen
    console.log("Token nach dem Löschen:", localStorage.getItem('token')); // Sollte 'null' sein
    window.location.href = "/login"; // Zur Login-Seite navigieren
};

  const resetCounter = () => {
    setCounter(900); // Zurücksetzen auf 15 Minuten
  };

  useEffect(() => {
    const decrementCounter = () => {
      setCounter((prevCounter) => prevCounter - 1);
    };

    // Event-Listener für Klicks hinzufügen
    window.addEventListener("click", resetCounter);

    const interval = setInterval(decrementCounter, 1000);

    return () => {
      window.removeEventListener("click", resetCounter);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (counter <= 0) {
      handleLogout();
    }
  }, [counter]);

  return (
    <div className="flex flex-row justify-between bg-[#179C7D] border-b-0 sticky top-0 z-50 font-poppins">
      <div className="grid grid-cols-9 gap-0 w-full">
        <a
          href="https://www.ise.fraunhofer.de/"
          className="col-span-2 my-2 ml-1 flex justify-center items-center"
        >
          <img src="/14_FhG_white.png" alt="Logo" className="mx-auto w-56 h-auto" />
        </a>
        <div className="col-span-1"></div> {/* Empty space */}
        <button
          onClick={() => props.scrollTo("Landing")}
          className={
            props.activeSection === "Landing"
              ? "flex justify-center items-center col-span-1 my-6 ml-1 border-2 border-white text-white py-1 px-2 rounded-xl text-lg w-full"
              : "flex justify-center items-center col-span-1 my-6 ml-1 text-white py-1 px-2 text-lg w-full"
          }
        >
          Start
        </button>
        <button
          onClick={() => props.scrollTo("Mesh")}
          className={
            props.activeSection === "Mesh"
              ? "flex justify-center items-center col-span-1 my-6 ml-1 border-2 border-white text-white py-1 px-2 rounded-xl text-lg w-full"
              : "flex justify-center items-center col-span-1 my-6 ml-1 text-white py-1 px-2 text-lg w-full"
          }
        >
          Mesh
        </button>
        <button
          onClick={() => props.scrollTo("Image")}
          className={
            props.activeSection === "Image"
              ? "flex justify-center items-center col-span-1 my-6 ml-1 border-2 border-white text-white py-1 px-2 rounded-xl text-lg w-full"
              : "flex justify-center items-center col-span-1 my-6 ml-1 text-white py-1 px-2 text-lg w-full"
          }
        >
          Layout
        </button>
        <button
          onClick={() => props.scrollTo("Optimization")}
          className={
            props.activeSection === "Optimization"
              ? "flex justify-center items-center col-span-1 my-6 ml-1 border-2 border-white text-white py-1 px-2 rounded-xl text-lg w-full"
              : "flex justify-center items-center col-span-1 my-6 ml-1 text-white py-1 px-2 text-lg w-full"
          }
        >
          Optimization
        </button>
        <button
          onClick={() => props.scrollTo("Results")}
          className={
            props.activeSection === "Results"
              ? "flex justify-center items-center col-span-1 my-6 ml-1 border-2 border-white text-white py-1 px-2 rounded-xl text-lg w-full"
              : "flex justify-center items-center col-span-1 my-6 ml-1 text-white py-1 px-2 text-lg w-full"
          }
        >
          Results
        </button>
      </div>
      <div className="flex items-center my-6 ml-4 mr-5">
        <button
          onClick={handleLogout}
          className="flex justify-center items-center border-2 border-white text-white py-1 px-4 rounded-xl text-lg mr-4"
        >
          Logout
        </button>
        <span className="text-white text-sm">{Math.floor(counter / 60)}m {counter % 60}s</span>
      </div>
    </div>
  );
}