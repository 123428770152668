import React from "react";
import { useState, useEffect, useRef } from "react";
import SimuDropDown from "./SimuDropDown";
import { minusSvg } from "./svgs";
import CustomInput from "./CustomInput";
import { dropDownSvg } from "./svgs";
import { useRecoilState } from "recoil";
import { simuParameters } from "../App";

function SimuRow(props) {
  const [open, setopen] = useState(false);
  const [currentSelect, setcurrentSelect] = useState(props.notUsedParams[0]);
  const [simuParams, setsimuParams] = useRecoilState(simuParameters);
  const ref = useRef();
  useOutsideAlerter(ref);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setopen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleChange = (newValue, edge) => {
    if (edge === "lower") {
      setsimuParams({
        ...simuParams,
        sweepParams: {
          ...simuParams.sweepParams,
          [props.id]: {
            ...simuParams.sweepParams[props.id],
            lower: parseFloat(newValue),
          },
        },
      });
    } else if (edge === "upper") {
      setsimuParams({
        ...simuParams,
        sweepParams: {
          ...simuParams.sweepParams,
          [props.id]: {
            ...simuParams.sweepParams[props.id],
            upper: parseFloat(newValue),
          },
        },
      });
    }
  };

  /*useEffect(() => {
    //console.log("SimuRow useEffect triggered for props.id:", props.id);
    const currentSweepParam = simuParams.sweepParams[props.id];
    if (
      !currentSweepParam ||
      currentSweepParam.name !== currentSelect.shortName ||
      currentSweepParam.lower !== currentSelect.range.lower ||
      currentSweepParam.upper !== currentSelect.range.upper
    ) {
      //console.log("Updating simuParams for props.id:", props.id);
      setsimuParams((prevParams) => ({
        ...prevParams,
        sweepParams: {
          ...prevParams.sweepParams,
          [props.id]: {
            name: currentSelect.shortName,
            lower: currentSelect.range.lower,
            upper: currentSelect.range.upper,
          },
        },
      }));
    }
  }, [currentSelect, props.id, simuParams.sweepParams, setsimuParams]);
  */

  useEffect(() => {
    console.log(props.id);
    setsimuParams({
      ...simuParams,
      sweepParams: {
        ...simuParams.sweepParams,
        [props.id]: {
          name: currentSelect.shortName,
          lower: currentSelect.range.lower,
          upper: currentSelect.range.upper,
        },
      },
    });
  }, [currentSelect]);
  

  const removeThisRow = async () => {
    props.setRows(props.rows.filter((item) => item !== props.id));
    setsimuParams({
      ...simuParams,
      sweepParams: { ...simuParams.sweepParams, [props.id]: undefined },
    });
  };

  return (
    <div className="flex flex-row w-full justify-center border-black">
      <button className="h-8 w-8 m-auto mr-5 " onClick={removeThisRow}>
        {minusSvg}
      </button>
      <div className="grid grid-cols-3 gap-4 w-full">
        <div>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Optimization parameter
          </label>
          <div ref={ref}>
            <button
              onClick={() => setopen(!open)}
              className="w-full flex flex-row justify-between content-center hover:border-[#005B7F] text-left bg-white text-gray-700 rounded-lg border-2 py-2 px-2 leading-tight focus:outline-none"
            >
              {currentSelect.fullName}
              {dropDownSvg}
            </button>
            {open ? (
              <SimuDropDown
                notUsedParams={props.notUsedParams}
                setcurrentSelect={setcurrentSelect}
                open={open}
                setopen={setopen}
                id={props.id}
              />
            ) : null}
            {/* {open ? openDropdown() : null} */}
          </div>
        </div>
        <div>
          <CustomInput
            label="Start"
            range={currentSelect.range}
            default={currentSelect.range.lower}
            name={currentSelect.shortName}
            unit={currentSelect.unit}
            onChange={(value) => handleChange(value, "lower")}
          />
        </div>
        <div>
          <CustomInput
            label="Stop"
            range={currentSelect.range}
            default={currentSelect.range.upper}
            name={currentSelect.shortName}
            unit={currentSelect.unit}
            onChange={(value) => handleChange(value, "upper")}
          />
        </div>
      </div>
    </div>
  );
}

export default SimuRow;
