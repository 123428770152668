import React, { useState,} from "react";
import MeshInput from "./MeshInput";
import Heatmap from "./Heatmap";
import ImageSection from "./ImageSection";
import Headline from "./Headline";
import Results from "./Results";
import SimuSection from "./SimuSection";
import Header from "./Header";
import Landing from "./Landing";
//import Handout from "./Handout";
import Login from "./login";
import { FOOTER_TEXT, VERSION_NUM } from "./svgs";

//const headlines = ["#Start", "#Mesh", "#Image", "#Optimization", "#Results", "#Landing"];

function Main({ isLoggedIn, onLoginSuccess }) {
  const [currentSection, setCurrentSection] = useState("Landing");
  //TODO: it could be Better!!
  const [imageParams,setimageParams] = useState({
    method: "thresh",
    thresh: 100,
    rawImage: null,
    layers: [],
    fileType : ""
  });
  const scrollTo = (id) => {
    const yOffset = -50;
    const element = document.querySelector(`#${id}`);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
    setCurrentSection(id); // Update currentSection when scrolling
  };

 // const headingElementsRef = useRef({});

  return (
    <>
      {!isLoggedIn && <Login onLoginSuccess={onLoginSuccess} isLoggedIn={isLoggedIn} />} {/* Pass isLoggedIn as a prop */}
      {isLoggedIn && (
        <div>
          <Header scrollTo={scrollTo} activeSection={currentSection} />
          <div className="grid grid-cols-3 gap-8 px-8 max-w-8xl mx-36 bg-gray-200 text-gray-700 font-poppins">
            <div id="Landing" className="col-span-3">
              <Landing scrollTo={scrollTo} />
            </div>
            <div id="Mesh" className="col-span-3">
              <Headline
                scrollTo={scrollTo}
                headline="Mesh Design"
                description="Configure your custom mesh parameters"
              />
            </div>
            <div className="">
              <MeshInput />
            </div>
            <div className="col-span-2">
              <Heatmap />
            </div>
            <div id="Image" className="col-span-3">
              <Headline
                scrollTo={scrollTo}
                headline="Layout"
                description="Take your image and configure the layout"
              />
            </div>
            <div className="col-span-3">
              <ImageSection imageOptions={imageParams} setimageOptions={setimageParams}/> {/* change this line to remove webcam access */}
            </div>
            <div id="Optimization" className="col-span-3">
              <Headline
                scrollTo={scrollTo}
                headline="Optimization"
                description="Explore your optimized screen design."
              />
            </div>
            <div className="col-span-3">
              <SimuSection imageOptions={imageParams}/>
            </div>
            <div id="Results" className="col-span-3">
              <Headline
                scrollTo={scrollTo}
                headline="Results"
                description="See how the image interacts with the specified meshes"
              />
            </div>
            <div className="col-span-3">
              <Results />
            </div>
          </div>
          <div className="fixed bottom-0 right-2 text-black-700 text-sm">
            {FOOTER_TEXT}
          </div>
          <div className="fixed bottom-0 left-2 text-black-700 text-sm">
              {VERSION_NUM}
            </div>
        </div>
      )}
    </>
  );
}

export default Main;